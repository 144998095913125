<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Notes
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
        @click="addNote"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Note</span>
      </button>
    </div>
    <div>
      <template v-for="note in notes">
        <div class="pb-6 last:pb-0" :key="note.note_id">
          <div class="bg-gray-200 p-4 flex flex-col rounded">
            <p class="whitespace-pre-wrap">{{ note.note }}</p>
            <div class="flex items-center border-t border-secondary mt-4 pt-4">
              <p class="text-gray-700 flex-1">
                {{ note.created_by }} on
                {{ $moment(note.created_at).format("DD/MM/YYYY [at] HH:mm") }}
                <span v-if="note.updated_by"
                  >(updated
                  {{
                    $moment(note.updated_at).format("DD/MM/YYYY [at] HH:mm")
                  }})</span
                >
              </p>
              <div class="inline-flex">
                <button
                  @click="deleteNote(note)"
                  type="button"
                  class="inline-flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                    />
                  </svg>
                  <span>Delete</span>
                </button>
                <button
                  @click="openEditNote(note)"
                  type="button"
                  class="ml-4 inline-flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    />
                  </svg>
                  <span>Edit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <p class="text-center" v-if="!notes || notes.length == 0">
      No notes have been added to this enquiry yet
    </p>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditNotePanelOpen"
        @close="addEditNotePanelOpen = false"
        :title="editingNote.note_id ? 'Edit Note' : 'Add Note'"
      >
        <add-edit-note :note="editingNote" @complete="closeNotePanel" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
import AddEditNote from "@/components/SalesProspects/Edit/AddEditNote.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";

export default {
  name: "Notes",
  props: {
    notes: Array,
    enquiryId: String,
  },
  components: {
    AddEditNote,
    ConfirmAlert,
    Panel,
    Notification,
  },
  data() {
    return {
      addEditNotePanelOpen: false,
      editingNote: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
    };
  },
  methods: {
    addNote: function() {
      this.editingNote = { note: "" };
      this.addEditNotePanelOpen = true;
    },
    closeNotePanel: async function(note) {
      try {
        let isUpdate = false;

        if (note.note_id) {
          isUpdate = true;
          await this.SalesProspectService.updateProspectNote(
            this.enquiryId,
            note.note_id,
            note.note
          );

          let idx = this.$_.findIndex(this.notes, (n) => {
            return n.note_id == note.note_id;
          });

          this.notes[idx].note = note.note;
          this.notes[idx].updated_at = note.updated_at;
          this.notes[idx].updated_by = note.updated_by;
        } else {
          let createResult = await this.SalesProspectService.createProspectNote(
            this.enquiryId,
            note.note
          );

          this.notes.unshift(createResult);
        }

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: `Note ${isUpdate ? "updated" : "added"}`,
                  close: onClose,
                },
              },
              `Note has been successfully ${isUpdate ? "updated" : "added"}`
            );
          },
          {
            position: "top-right",
          }
        );
        this.addEditNotePanelOpen = false;
        this.editingNote = {};
      } catch (err) {
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There has been a problem saving your notes"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    openEditNote(note) {
      this.editingNote = {
        note_id: note.note_id,
        note: note.note,
      };

      this.addEditNotePanelOpen = true;
    },
    handleDeleteNote: async function(result) {
      if (result) {
        try {
          await this.SalesProspectService.deleteProspectNote(
            this.enquiryId,
            this.confirmAlert.data.note_id
          );
          let idx = this.$_.findIndex(this.notes, (n) => {
            return n.note_id == this.confirmAlert.data.note_id;
          });

          this.$delete(this.notes, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Success",
                    close: onClose,
                  },
                },
                "Note deleted"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the note"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteNote(note) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteNote,
        message: "Are you sure you want to delete this note? Cannot be undone",
        showing: true,
        data: note,
      };
    },
  },
};
</script>
